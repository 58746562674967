<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: [Number, String, Object],
    valueKey: String,
    placeholder: String,
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    filterable: { type: Boolean, default: true },
    optionsType: { type: String, default: 'default' }, // default, local, remote
    defaultOptions: { type: Array, default: () => [] },
    localOptionsKey: { type: String, default: '' },
    params: { type: Object, default: null },
    fuzzyMatching: { type: Boolean, default: false },
    fuzzyMatchingKey: { type: String, default: 'name' },
    pageable: { type: Boolean, default: false },
    limit: { type: Number, default: 15 },
    remoteOptionsApi: { type: Function, default: null },
    remoteLabelKey: { type: String, default: 'name' },
    remoteValueKey: { type: String, default: 'uuid' },
    diyStyle: { type: String, default: '' }
  },
  data() {
    return {
      options: this.initOptions(),
      keyword: '',
      start: 0,
      loading: false
    };
  },
  watch: {
    params() {
      this.options = [];
      this.keyword = '';
      this.start = 0;
    },
    keyword() {
      if (this.fuzzyMatching) {
        this.options = [];
        this.start = 0;
        this.findOptions();
      }
    }
  },
  methods: {
    initOptions() {
      let options = [];
      if (this.optionsType === 'default') {
        options = JSON.parse(JSON.stringify(this.defaultOptions));
      }
      if (this.optionsType === 'local') {
        options = JSON.parse(localStorage.getItem(this.localOptionsKey)) || [];
      }
      return options;
    },
    initChangeListener() {
      if (this.$refs['__infinite-scroll-select-input-ref__']) {
        this.nodeListConvertToArray(
          this.nodeListConvertToArray(this.$refs['__infinite-scroll-select-input-ref__']['$vnode'].elm.childNodes)
            .find(i => i.className && i.className.startsWith('el-input')).childNodes
        ).find(i => i.className && i.className === 'el-input__inner').addEventListener('input', this.debounce((e) => {
          this.keyword = e.target.value;
        }, 300));
      }
    },
    nodeListConvertToArray(nodeList) {
      let array = null;
      try {
        array = Array.prototype.slice.call(nodeList, 0);
      } catch (ex) {
        array = [];
        for (let i = 0; i < nodeList.length; i++) {
          array.push(nodeList[0]);
        }
      }
      return array;
    },
    debounce(func, delay) {
      let timer;
      return (...args) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    filterMethod() {
    },
    initScrollListener() {
      if (this.$refs['__infinite-scroll-select-scrollbar-ref__']) {
        this.$refs['__infinite-scroll-select-scrollbar-ref__'].parentNode.parentNode.addEventListener('scroll', (e) => {
          if (this.start === -1 || this.loading) return;
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          const distance = Math.abs(scrollHeight - (scrollTop + clientHeight));
          if (distance <= 1) {
            this.findOptions(false);
          }
        });
      }
    },
    handleVisibleChange(val) {
      if (this.optionsType === 'remote' && this.options.length === 0 && val) {
        this.findOptions();
      }
    },
    handleChange(val) {
      if (this.optionsType === 'local' && val && this.options.findIndex(i => i.value === val) === -1) {
        this.options.push({ label: val, value: val });
      }
      this.$emit('change', val);
    },
    handleClear() {
      this.$emit('clear');
    },
    findOptions(init = true) {
      this.loading = true;
      // 准备参数
      let params = {};
      if (this.params) params = { ...this.params };
      if (this.fuzzyMatching) params[this.fuzzyMatchingKey] = this.keyword;
      if (this.pageable) {
        params.start = init ? 0 : this.start;
        params.limit = this.limit;
      }

      if (this.remoteOptionsApi) {
        this.remoteOptionsApi(params)
          .then(res => {
            this.loading = false;
            if (res.code === 0) {
              res.data = res.data || [];
              res.data = res.data.map(i => ({
                label: i[this.remoteLabelKey],
                value: this.valueKey ? i : i[this.remoteValueKey]
              }));
              if (this.pageable) {
                this.start = res.data.length < params.limit ? -1 : params.start + params.limit;
                this.options = init ? res.data : [...this.options, ...res.data];
              } else {
                this.options = res.data;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    if (this.optionsType === 'remote' && this.fuzzyMatching) {
      this.initChangeListener();
    }
    if (this.optionsType === 'remote' && this.pageable) {
      this.initScrollListener();
    }
  },
  destroyed() {
    if (this.optionsType === 'local') {
      localStorage.setItem(this.localOptionsKey, JSON.stringify(this.options));
    }
  }
};
</script>

<template>
  <el-select
    class="infinite-scroll-select"
    :value="value"
    :value-key="valueKey"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="optionsType === 'local' || filterable"
    :allow-create="optionsType === 'local'"
    :loading="options.length === 0 && loading"
    :style="diyStyle"
    :filter-method="optionsType === 'remote' && fuzzyMatching ? filterMethod : null"
    ref="__infinite-scroll-select-input-ref__"
    @visible-change="handleVisibleChange"
    @change="handleChange"
    @clear="handleClear"
  >
    <div ref="__infinite-scroll-select-scrollbar-ref__">
      <el-option v-for="o in options" :key="valueKey ? o.value[valueKey] : o.value" :label="o.label" :value="o.value" />
      <div v-if="options.length > 0 && loading" style="text-align: center">
        <i class="el-icon-loading" style="font-size: 20px" ></i>
      </div>
    </div>
  </el-select>
</template>

<style scoped lang="scss">
  .infinite-scroll-select {
    &.el-select {
      /*width: 160px;*/
      ::v-deep .el-input.el-input--small {
        .el-input__inner {
          border-radius: 4px;
        }
      }
    }
  }
</style>
