import request from '@/utils/request';

export function orgInfoUnion() {
  return request({
    url: `/v1/account/org/infoUnion`,
    method: 'GET',
  })
}

export function orgModule() {
  return request({
    url: `/v1/org/role/orgModules`,
    method: 'GET',
  })
}

export function orgInfoModify(data) {
  return request({
    url: `/v1/account/org/modify`,
    method: 'POST',
    data
  })
}

export function orgRelate(params) {
  return request({
    url: `/v1/account/org/relate`,
    method: 'GET',
    params
  })
}

