<template>
  <div class="edit">
    <step-container :name="'编辑支付'">
      <span slot="back" class="edit-remark">相关配置请联系技术人员进行操作，如不能确认请联系客服提供技术支持</span>
      <template slot="step">
        <template>
          <el-button type="text" v-if="$btnCheck('order_setting_pay_auth')" @click="handleAuth">
            <i class="iconfont icon-audit"/>
            客户授权
          </el-button>
        </template>
      </template>


    </step-container>
    <div class="edit-container">
      <el-tabs v-model="step" class="detail-container">
        <el-tab-pane name="info" label="支付信息" v-if="$btnCheck('order_setting_pay_info')">
          <pay-container :uuid="$route.params.uuid"></pay-container>
        </el-tab-pane>
        <el-tab-pane name="log" label="操作日志" v-if="$btnCheck('order_setting_pay_logs')">
          <detail-log :uuid="$route.params.uuid"></detail-log>
        </el-tab-pane>
        <el-tab-pane name="auth" label="授权信息" v-if="$btnCheck('order_setting_pay_auth')">
          <detail-auth ref="auth" :uuid="$route.params.uuid"></detail-auth>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog :visible.sync="authPayVisible"
               width="560px"
               style="padding-right:24px"
               title="授权客户">
      <el-form label-width="80px">
        <el-form-item label="客户名称">
          <InfiniteScrollSelect
            v-model="customer.orgName"
            placeholder="请输入客户名称"
            options-type="remote"
            valueKey="name"
            :fuzzyMatching="true"
            :pageable="true"
            :remoteOptionsApi="orgRelate"
            @change="handleChange"
          />
        </el-form-item>

      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleAuthCommit">确定</el-button>
        <el-button @click="authPayVisible=false">取消</el-button>
      </template>

    </el-dialog>
  </div>

</template>

<script>

  import PayContainer from '../Component/pay_container'
  import DetailLog from './detail_log'
  import DetailAuth from './detail_auth'
  import InfiniteScrollSelect from '@/components/InfiniteScrollSelect';
  import {mapGetters} from "vuex";

  import {orgRelate} from '@/api/org/basic'

  export default {
    name: 'equipment-pay-create',
    components: {
      PayContainer,
      DetailLog,
      DetailAuth,
      InfiniteScrollSelect
    },
    computed: {
      ...mapGetters(['prove']),
    },
    mounted: function () {
      this.pay.proveName = this.prove.name
    },
    data: function () {
      return {
        data: [],
        step: 'info',
        authPayVisible: false,
        orgRelate,
        customer: {
          uuid: '',
          orgUuid: '',
          orgName: '',
          proveName: ''
        },
        pay: {
          uuid: '',
          name: '',
          proveName: '',

        }
      }
    },
    methods: {
      handlePayCreate() {
        if (!this.pay.name) {
          this.$message.warning('请输入支付名称')
          return
        }
        this.$api('mkt.pay.create',{}, this.pay).then(data => {
          this.pay.uuid = data
          this.current += 1
        })
      },
      handleAuth() {
        this.customer.uuid = this.$route.params.uuid
        this.customer.proveName = this.prove.name
        this.authPayVisible = true
      },
      handleChange(value) {
        this.customer.orgName = value.name
        this.customer.orgUuid = value.uuid
      },
      handleAuthCommit() {
        if (!this.customer.orgUuid) {
          this.$message.warning('请选取授权客户')
          return
        }
        this.$api('mkt.pay.authorize', {}, this.customer).then(() => {
          this.$message.success('授权成功')
          this.authPayVisible = false
          this.$refs.auth.loadInfos(this.$route.params.uuid)
          return
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .edit {
    height: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    &-remark {
      @include font-little-h();
      color: $remark-text-color;
      margin-left: $small-space;
    }

    &-container {
      margin: $middle-space;
      padding: 0 $container-padding;
      padding-top: $middle-space;
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      overflow-y: scroll;

    }

    .commit {
      margin-top: $large-space;
    }
  }
</style>
