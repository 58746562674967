<template>
  <div class="wechat">
    <el-form label-width="184px" class="wechat-info-form">
      <el-form-item label="公众号/小程序AppId">
        <el-input v-model="common.appId" size="small" placeholder="请输入公众号/小程序AppId"></el-input>
      </el-form-item>
      <el-form-item label="公众号/小程序AppSecret">
        <el-input v-model="common.appSecret" size="small" placeholder="请输入公众号/小程序AppSecret"></el-input>
      </el-form-item>
      <el-form-item label="商户号">
        <el-input v-model="common.mchId" size="small" placeholder="请输入商户号"></el-input>
      </el-form-item>
      <el-form-item label="商户V3Secret">
        <el-input v-model="common.mchSecret" size="small" placeholder="请输入商户V3Secret"></el-input>
      </el-form-item>
      <el-form-item label="授权证书编号">
        <el-input v-model="common.certNumber"
                  size="small"
                  placeholder="请输入授权证书"></el-input>
      </el-form-item>
      <el-form-item label="授权证书">
        <el-input type="textarea" v-model="common.certPem"
                  placeholder="请输入授权证书"></el-input>
      </el-form-item>
      <el-form-item label="授权私钥">
        <el-input type="textarea" v-model="common.certKey"
                  placeholder="请输入授权私钥"></el-input>
      </el-form-item>
      <el-form-item label="设为默认支付">
        <el-switch v-model="common.isSelect" ></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleCreate">保存</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    props: {
      success: {
        type: Function,
        default: null
      },
      uuid: {
        type: String,
        default : ''
      }
    },
    computed: {
      ...mapGetters(['prove']),
    },
    mounted: function () {
      this.loadWxSub(this.uuid)
    },
    data: function () {
      return {
        common: {
          appId: '',
          appSecret: '',
          mchId: '',
          mchSecret: '',
          certNumber: '',
          certPem: '',
          certKey: '',
          proveName: '',
          isSelect: false,
        },
        commonRule: [
          {name: 'appId', error: 'AppId'},
          {name: 'appSecret', error: 'AppSecret'},
          {name: 'mchId', error: '商户号'},
          {name: 'mchSecret', error: 'v3密钥'},
          {name: 'certNumber', error: '授权证书编号'},
          {name: 'certPem', error: '授权证书'},
          {name: 'certKey', error: '授权证书私钥'},
        ],
      }
    },
    methods: {
      loadWxSub(uuid) {
        this.$api('mkt.pay.wxSub', {uuid: uuid}).then(data => {
          this.common = data
        })
      },
      handleCreate() {
        for (let i = 0; i < this.commonRule.length; i++) {
          let value = this.common[this.commonRule[i].name]
          if (!value) {
            this.$message.error('请输入'+this.commonRule[i].error)
            return
          }
        }

        this.common.proveName = this.prove.name
        this.common.uuid = this.uuid


        this.$api('mkt.pay.bindWxSub', {},this.common).then(() => {
          this.$message.success('绑定成功')
          if (this.success != null) {
            this.success()
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .el-form-item{
    margin-bottom: $small-space;
  }
</style>
