<template>
  <div class="pay">
    <el-table
      :data="items">
      <el-table-column label="支付方式" width="120px" align="center">
        <div slot-scope="scope">
          <p class="pay-item-title">{{scope.row.name}}</p>
        </div>
      </el-table-column>
      <el-table-column label="支付选项" align="center">
        <el-row slot-scope="scope" class="pay-item-opr">
          <el-col v-for="opt in scope.row.options" :key="opt.name" :span="8" class="pay-item-info">
            <img :src="opt.img"/>
            <p class="pay-item-info-name">
              {{opt.name}}
              <el-tag size="small" v-if="opt.status !== 0" :type="status[opt.status].type"
                      :plain="status[opt.status].plain">{{status[opt.status].name}}
              </el-tag>
            </p>
            <p class="pay-item-info-remark"><span v-html="opt.remark"></span></p>
            <el-button size="small" type="primary" v-if="opt.status === 0" @click="handleEdit(opt)">申请</el-button>
            <el-button size="small" type="primary"
                       v-if="opt.status !== 0"
                       @click="handleEdit(opt)">编辑
            </el-button>
            <el-button size="small" type="primary" v-if="opt.status >=4 && opt.status <=5" @click="handleQr(opt)">点击验证
            </el-button>
            <template v-if="opt.status === 7">
              <el-button size="small" type="primary">测试支付</el-button>
              <el-button size="small"  type="primary"
                         v-if="!opt.selected && $btnCheck('order_setting_pay_use')" @click="paySetDefault(scope.row, opt)"
                         plain>启用
              </el-button>
              <el-button size="small" plain disabled v-else>已启用</el-button>
            </template>

          </el-col>
        </el-row>
      </el-table-column>
    </el-table>

    <el-dialog
      title="提示"
      :visible.sync="qr.visible"
      width="30%">
      <div class="qr">
        <qrcode class="qr" :value="qr.url" :options="{ width: 156 }"></qrcode>
        <p>请使用【{{qr.name}}】实名微信账号进行扫码确认</p>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="qr.visible = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="信息绑定"
      :visible.sync="bind.visible"
      width="30%">
      <el-form label-width="120px">
        <el-form-item label="咖博士支付编号">
          <el-input v-model="bind.drCoffeePayUuid" size="small" placehoolder="请输入咖博士支付编号"></el-input>
          <p class="pay-info">编号为20开头的字符串</p>
          <p class="pay-tip">示例值：202104301009507f24dac0a801000</p>
        </el-form-item>

        <el-form-item label="设为默认支付">
          <el-switch v-model="bind.isSelect"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleBindDrCoffee" type="primary" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="直连商户"
               :visible.sync="redirect.visible"
               width="40%">
      <wx-sub :uuid="this.uuid" :success="subSuccess"></wx-sub>

    </el-dialog>

    <el-dialog title="支付宝授权"
               :visible.sync="auth.visible"
               width="30%">
      <div class="qr">
        <qrcode class="qr" :value="auth.url" :options="{ width: 156 }"></qrcode>
        <p>请使用手机支付宝进行扫码确认</p>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="handleAliAuth">确 定</el-button>
      </div>

    </el-dialog>


  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  import WxSub from './sub'

  export default {
    name: 'pay-container',
    components: {
      WxSub
    },
    props: {
      uuid: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters(['prove']),
    },
    watch: {
      uuid(val) {
        this.loadPayInfo(val)
      }
    },
    mounted: function () {
      this.loadPayInfo(this.uuid)
    },
    data: function () {
      return {
        items: [],
        redirect: {
          visible: false,
        },
        auth: {
          visible: false,
          url: '二维码无效',
        },
        qr: {
          visible: false,
          name: '',
          url: '二维码无效',
        },
        bind: {
          visible: false,
          proveName: '',
          payChanel: 0,
          uuid: '',
          drCoffeePayUuid: '',
          isSelect: false,
        },
        status: [
          {name: '申请', type: 'primary', plain: false, action: 'apply'},
          {name: '等待提交', type: 'warning', plain: true},
          {name: '审核中', type: 'warning', plain: true},
          {name: '已拒绝', type: 'danger', plain: true, action: 'refuse'},
          {name: '超管验证', type: 'warning', plain: true},
          {name: '法人验证', type: 'warning', plain: true, action: 'waiting'},
          {name: '待签约', type: 'warning', plain: true, action: 'waiting'},
          {name: '开通成功', type: 'success', plain: true},
        ]
      }
    },
    methods: {
      subSuccess() {
        this.redirect.visible = false
      },
      loadPayInfo(uuid) {
        this.$api('mkt.pay.chanelInfo',{uuid: uuid}).then(data => {
          let name = {}
          data.forEach(item => {
            let v = name[item.parentName]
            if (!v) {
              v = []
            }
            v.push(item)
            name[item.parentName] = v
          })
          let items = []
          for (let i in name) {
            let arr = name[i]
            arr.sort((a, b) => {
              return a.payChanelType > b.payChanelType ? 1 : -1
            })
            items.push({name: i, options: arr})
          }


          this.items = items
        })
      },
      paySetDefault(item, opt) {
        this.$api('mkt.pay.chanelInfoDefault',{}, {
          uuid: this.uuid,
          payChanel: opt.payChanel,
          payChanelType: opt.payChanelType,
          proveName: this.prove.name,
        }).then(() => {
          (item.options || []).forEach(i => {
            i.selected = 0
          })
          opt.selected = 1
          this.$message.success('配置成功')
        })
      },
      handleAliAuth() {
        this.auth.visible = false
        this.loadPayInfo(this.uuid)
      },
      handleEdit(opt) {
        switch (opt.payChanelType) {
          case 1:
            this.$router.push({name: 'order.setting.pay.detail.wx', params: {uuid: this.uuid}})
            return
          case 2:
            this.bind.payChanel = 2
            this.bind.visible = true
            return
          case 3:
            this.redirect.visible = true
            return
          case 4:
            this.$api('mkt.pay.aliApply',{uuid: this.uuid}).then(data => {
              this.auth.url = data
              this.auth.visible = true
            })
            return
          case 5:
            this.bind.payChanel = 3
            this.bind.visible = true
            return
        }
      },
      handleQr(opt) {
        this.$api('mkt.pay.wxApplyDetail', {uuid: this.$route.params.uuid}).then(data => {
          switch (opt.status) {
            case 4:
              this.qr.name = data.contactName
              break;
            case 5:
              this.qr.name = data.idCardName
              break;
          }
          this.qr.url = data.remark
          this.qr.visible = true
        })
      },
      handleBindDrCoffee() {
        if (!this.bind.drCoffeePayUuid) {
          this.$message.warning('请输入编号信息')
          return
        }
        this.bind.proveName = this.prove.name
        this.bind.uuid = this.uuid
        this.$api('mkt.pay.bindDrCoffee',  {}, this.bind).then(() => {
          this.$message.success('绑定成功')
          this.loadPayInfo(this.uuid)
          this.bind.drCoffeePayUuid = ''
          this.bind.visible = false
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

  .qr {
    text-align: center;
  }

  .pay-info {
    @include font-little-h();
    color: $remark-text-color;
  }

  .pay-tip {
    @include font-little();
    color: $remark-text-color;
  }

  .pay {
    ::v-deep .el-table tbody tr:hover > td {
      background-color: #fff !important
    }

    &-item {
      &-title {
        @include font-medium();
        color: $import-text-color;
      }

      &-opr {
      }


      &-info {
        padding-bottom: $middle-space;

        &-name {
          @include font-medium();
        }

        &-remark {
          margin: $small-space 0;
          @include font-little();
          color: $remark-text-color;
          height: 36px;
        }

        img {
          width: 120px;
          height: 80px;
        }
      }

      &-info:hover {
        border: 1px solid $theme-color;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
</style>
